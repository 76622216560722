enum Event {
    TrackingUsing = "tracking_using",
    TrackingFound = "tracking_found",
    TrackingNotFound = "tracking_not_found",
    TrackingFailed = "tracking_failed",
    TrackingExpanded = "tracking_expanded",
    WaitingQueueOpened = "waiting_queue_opened",
}

export const useAnalytics = () => {
    const gtm = useGtm();

    return {
        trackingUsing: (registrationNumber: string) => gtm?.trackEvent({
            event: Event.TrackingUsing,
            registration_number: registrationNumber,
        }),
        trackingFound: (registrationNumber: string) => gtm?.trackEvent({
            event: Event.TrackingFound,
            registration_number: registrationNumber,
        }),
        trackingNotFound: (registrationNumber: string) => gtm?.trackEvent({
            event: Event.TrackingNotFound,
            registration_number: registrationNumber,
        }),
        trackingFailed: (registrationNumber: string) => gtm?.trackEvent({
            event: Event.TrackingFailed,
            registration_number: registrationNumber,
        }),
        trackingExpanded: (registrationNumber: string) => gtm?.trackEvent({
            event: Event.TrackingExpanded,
            registration_number: registrationNumber,
        }),
        waitingQueueOpened: (checkpointName: string) => gtm?.trackEvent({
            event: Event.WaitingQueueOpened,
            checkpoint_name: checkpointName,
        }),
    };
}